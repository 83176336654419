body > *,
#root > * {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Hylia Serif Beta";
  src: url("./assets/HyliaSerifBeta-Regular.otf") format("opentype");
}

h1,
h2 {
  font-family: "Hylia Serif Beta", serif;
}

h3,
h4,
h5,
h6 {
  font-family: "Open Sans", serif;
}

p {
  font-family: "Open Sans", serif;
}

.background-image {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("assets/seamless-bg.png");
  background-repeat: repeat;
  min-height: 100vh;
  overflow: hidden;
}

.navbar-fretwork {
  background-image: url("./assets/navbar-fretwork.png");
  background-repeat: repeat-x;
  background-position: bottom;
  height: 3vmin;
}

.ingredient-image {
  width: 50px;
  height: auto;
}

.recipe-image {
  width: 70px;
  height: auto;
}

.navbar {
  height: 16vmin;
}

.App {
  text-align: center;
}

.App-logo {
  height: 16vmin;
  pointer-events: none;
}

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

.ingredient-image {
  max-width: 50px;
  height: 45px;
}

.heart-image {
  max-width: 19px;
  height: 14px;
}

.recipe-description {
  word-wrap: normal;
  width: 280px;
  text-align: center;
}
